






































































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';
import  Api from '../../router/api';
import 'jodit/build/jodit.min.css'
import { JoditEditor } from 'jodit-vue'

@Component({
  components: {
    MyNav,JoditEditor
  }
})
export default class Home extends Vue {

  public list: any = null
  public loading = false;
  public error = false;
  public edit: any = undefined;
  public boxMsg = '';
  public obj: any = this.reset();

  public reset() {
    return {    
      _id: null,
      name: '',
      slug: '',
      client: this.$route.params.code,
      bgLogin : '',
      bgLive : '',
      typeLive : null,
      hasPrize: false,
      hasQuestion: false,
      question: '',
      typeValidation : null,
      typeIdioma : null,
      linkExternal : null,
      addInputs : [],
      extraInputs : null,
      validationStrings : null,
      languages : {
        pt:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        en:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        es:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        }
      },
      addInput: null,
      chatLink: null
    }
  }

  public async atualiza(){
    this.list = null
    this.obj = this.reset();
    this.loading = true;
    const obj = await axios.post(process.env.VUE_APP_API+"live-users",{live:this.$route.params.code})
    .then((d) => {return {error: false, body : d.data}; })    
    if (!obj.error){
      this.list = obj.body
      document.title = `Admin - Lista de Usuarios`;  
    }
    this.loading = false;
  }

  public removeImg(type: string){
    if (type == 'bgLogin')
      this.obj.bgLogin = '';
    if (type == 'bgLive')
      this.obj.bgLive = '';  
  }

  public async created(){
    this.loading = true;
    await this.atualiza();
  }


}
